<template>
  <div class="v-stack gap-5 h-stretch">
    <div class="pane-left gap-3">
      <div class="h-stack gap-3">
        <label style="width: 40px">Client</label>
        <select
          class="fixed"
          v-model="selectedClient"
          @change="onClientSelected()"
        >
          <option v-for="client in clients" :key="client">
            {{ client }}
          </option>
        </select>
      </div>
      <div class="pane-right gap-3">
        <input type="text" placeholder="Type to search..." v-model="search" />
        <button @click="$router.push('/projects/add')" class="add">New</button>
      </div>
    </div>
    <div class="h-stack gap-3 h-start">
      <div class="h-stack gap-3" v-for="state in states" :key="state.name">
        <label
          >{{
            state.name.charAt(0).toUpperCase() + state.name.slice(1)
          }}:</label
        >
        <input type="checkbox" v-model="state.value" />
      </div>
    </div>
    <Table
      :items="filteredProjects"
      :defaultColumn="2"
      :defaultAscending="true"
      v-on:rowSelected="$router.push('/projects/detail/' + $event._id)"
      css="60px 60px 1fr 80px 80px 200px auto"
      :columns="[
        {
          name: 'State',
          sort: 'alphabet',
          path: ['state'],
        },
        {
          name: 'Icon',
          sort: 'alphabet',
          path: ['icon'],
        },
        {
          name: 'Project',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Created',
          sort: 'date',
          path: ['createdDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Deadline',
          sort: 'date',
          path: ['deadline'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Vimeo',
          sort: 'alphabet',
          path: ['links'],
          formatType: 'link',
          formatMap: (links) => (links.length > 0 ? links[0].url : null),
        },
        {
          name: 'Info',
        },
      ]"
    >
      <template v-slot:Info="{ item }">
        <div class="icon-square" v-bind:class="getIconClass(item)">
          <i class="fas fa-euro-sign"></i>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import moment from "moment";
import constants from "@/constants.js";
import Fuse from "fuse.js";

export default {
  data() {
    return {
      projects: [],
      selectedClient: "All",
      states: constants.projectState.map((state) => {
        return {
          name: state,
          value: state != "canceled" && state != "finished" ? true : false,
        };
      }),
      clients: [],
      search: "",
    };
  },
  components: {
    Table,
  },
  computed: {
    filteredProjects() {
      let projects = this.projects.slice();

      const query = this.search.trim();
      if (query.length > 0) {
        const options = {
          keys: ["name"],
          threshold: 0.3,
          ignoreLocation: true,
        };
        const fuse = new Fuse(projects, options);
        return fuse.search(query).map((resultItem) => resultItem.item);
      }

      for (const state of this.states) {
        if (!state.value) {
          projects = projects.filter((item) => item.state != state.name);
        }
      }

      if (this.selectedClient != "All") {
        projects = projects.filter(
          (item) => item.client.name == this.selectedClient
        );
      }

      return projects;
    },
  },
  methods: {
    ...mapActions(["getProjects"]),
    onClientSelected() {
      this.$router.push("/projects?client=" + this.selectedClient);
    },
    getIconClass(project) {
      if (project.selectedPricelists.length > 0) {
        return "green";
      }
      if (project.pricelists.length > 0) {
        return "yellow";
      }
      return "red";
    },
    refresh() {
      this.getProjects()
        .then((projects) => {
          this.projects = projects.sort((a, b) => {
            const start = moment(a.createdDate);
            const end = moment(b.createdDate);
            return end.diff(start, "seconds", true);
          });

          this.clients = Array.from(
            new Set(projects.map((item) => item.client.name))
          );

          this.clients = ["All"].concat(
            this.clients.sort((a, b) => a.localeCompare(b, "cs"))
          );

          if (this.$route.query.client) {
            this.selectedClient = this.$route.query.client;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
select.fixed {
  width: 160px;
}

a,
label,
select,
input {
  align-self: center;
}

.icon-square {
  padding: 5px;
  border-radius: 3px;
}

.icon-square.green {
  background-color: #42b983;

  i {
   color: white;
  }
}

.icon-square.red {
  background-color: #e45343;

  i {
   color: white;
  }
}

.icon-square.yellow {
  background-color: orange;

  i {
   color: white;
  }
}
</style>
